"use client";

import useSupabase from "../app/hooks/useSupabase";
import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Offcanvas,
  Button,
  Spinner,
} from "react-bootstrap";
import { FaGithub, FaGoogle } from "react-icons/fa";
import { IconFuegoStats } from "./icons";
import { colorSpinner } from "../lib/colors";

export default function Authenticate({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const supabase = useSupabase();

  async function signInWithGitHub() {
    setIsAuthenticating(true);
    await supabase.auth.signInWithOAuth({
      provider: "github",
      options: {
        redirectTo: window.location.href,
      },
    });
  }

  async function signInWithGoogle() {
    setIsAuthenticating(true);
    await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: window.location.href,
      },
    });
  }

  return (
    <Offcanvas
      show={show}
      onHide={onClose}
      placement="bottom"
      className="responsive-offcanvas">
      <Offcanvas.Body className="pt-3">
        <Container fluid>
          <Row>
            <Col>
              <h2 className="mb-2 d-flex align-items-center justify-content-center">
                <IconFuegoStats size={32} simple className="me-1" />
                <span>Welcome</span>
              </h2>
              {isAuthenticating ? null : (
                <p className="text-center mb-3">
                  Authenticate using Google or Github.
                </p>
              )}
            </Col>
          </Row>
          {isAuthenticating ? (
            <Row className="mt-1 mb-2">
              <Col>
                <div className="w-100 d-flex justify-content-center">
                  <Spinner animation="grow" style={{ color: colorSpinner }} />
                </div>
                <div className="w-100 d-flex justify-content-center text-secondary">
                  Authenticating...
                </div>
              </Col>
            </Row>
          ) : (
            <Row className="gx-2 flex-column">
              <Col xs={12} className="mb-1">
                <Button
                  className="w-100 d-flex align-items-center justify-content-center"
                  variant="primary"
                  onClick={signInWithGoogle}>
                  <span className=" d-flex align-items-center justify-content-center">
                    <FaGoogle className="me-1" />
                    Google
                  </span>
                </Button>
              </Col>
              <Col xs={12} className="mb-4">
                <Button
                  className="w-100"
                  variant="outline-primary"
                  onClick={signInWithGitHub}>
                  <span className=" d-flex align-items-center justify-content-center">
                    <FaGithub className="me-1" />
                    Github
                  </span>
                </Button>
              </Col>
              <Col xs={12}>
                <Button
                  className="w-100"
                  variant="outline-secondary"
                  onClick={onClose}>
                  Close
                </Button>
              </Col>
            </Row>
          )}
        </Container>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
